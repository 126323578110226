import createAction from '../services/create-action.js';
import { fetchCounters } from '../../app/actions/fetch-counters.js';
import { isExperimentEnabled } from '../../app/selectors/experiments-selectors';
import { EXPERIMENT_ENABLE_RICH_CONTENT_SCHEMA_CLIENT } from '@wix/communities-forum-client-commons';

export const FETCH_CATEGORIES_REQUEST = 'categories/FETCH_REQUEST';
export const FETCH_CATEGORIES_SUCCESS = 'categories/FETCH_SUCCESS';
export const FETCH_CATEGORIES_FAILURE = 'categories/FETCH_FAILURE';

export const fetchCategoriesRequest = createAction(FETCH_CATEGORIES_REQUEST);
export const fetchCategoriesSuccess = createAction(
  FETCH_CATEGORIES_SUCCESS,
  (payload) => payload,
  (payload, meta) => meta,
);
export const fetchCategoriesFailure = createAction(FETCH_CATEGORIES_FAILURE);

const fetchCategoriesBase = (path) => {
  return (dispatch, getState, { request }) => {
    const isRichContentSchemaEnabled = isExperimentEnabled(
      getState(),
      EXPERIMENT_ENABLE_RICH_CONTENT_SCHEMA_CLIENT,
    );
    dispatch(fetchCategoriesRequest());
    const promise = request(`${path}${isRichContentSchemaEnabled ? '?useRichContent=true' : ''}`);

    return promise
      .then(
        (categories) => {
          dispatch(fetchCounters({ categoryIds: categories.map((c) => c._id) }));
          dispatch(fetchCategoriesSuccess(categories));
        },
        () => dispatch(fetchCategoriesFailure()),
      )
      .then(() => promise);
  };
};

export const fetchAllCategories = () => fetchCategoriesBase('/categories/all');
export const fetchSubcategories = (categoryId) =>
  fetchCategoriesBase(`/categories/${categoryId}/subcategories`);
