import { MODAL_TYPE_CREATE_POST } from '../components/modals/post-create-modal/post-create-modal-type';
import sanitizeContent from '../services/sanitize-content';
import {
  EXPERIMENT_ENABLE_RICH_CONTENT_SCHEMA_CLIENT,
  extractHashtagsFromContent,
  extractMentionsFromContent,
} from '@wix/communities-forum-client-commons';

import { navigateWithinForum } from '../../common/actions/navigate-within-forum';
import { getCategory } from '../../common/selectors/categories-selectors';
import { closeModal, openModal } from '../../common/modals/framework/store/modal-actions';
import { createPromisifiedAction } from '../../common/actions-promisifier/create-promisified-action';
import { isExternalHookResolve } from '../../common/services/external-hooks';
import { createExternalHookRejectHandler } from '../../common/services/external-hooks-flow';
import {
  createHash,
  tokenName,
  getUniquePostPayloadString,
} from '@wix/communities-forum-universal/dist/src/services/forum-hs';
import { createAction } from '@reduxjs/toolkit';
import { AppDispatch } from '../reducers/app-dispatch';
import { Dispatch, RootState } from '../types/store-types';
import { CommonRequest } from '../types';
import { MODAL_TYPE_POST_PENDING_APPROVAL } from '../components/modals/post-pending-approval-modal/post-pending-approval-modal-type';
import { getCaptchaToken } from '../services/get-captcha-token';
import { isExperimentEnabled } from '../selectors/experiments-selectors';

interface Post {
  _id: string;
  categoryId: string;
  content: any;
}

export interface CreatePostSuccess extends Post {
  postId: string;
  postType: string;
  slug: string;
  draft?: boolean;
  hashtags?: string[];
}

export const CREATE_POST_REQUEST = 'post/CREATE_REQUEST';
export const CREATE_POST_SUCCESS = 'post/CREATE_SUCCESS';
export const CREATE_POST_FAILURE = 'post/CREATE_FAILURE';
export const CAPTCHA_ERROR_CODE = 15;

export const createPostRequest = createAction(CREATE_POST_REQUEST);
export const createPostSuccess = createAction(
  CREATE_POST_SUCCESS,
  (payload: CreatePostSuccess, meta: { isCaptchaUsed: boolean; wasCategorySelected: boolean }) => {
    return { payload, meta };
  },
);
export const createPostFailure = createAction(CREATE_POST_FAILURE, (meta) => ({
  payload: undefined,
  meta,
}));

export function createPost(post: Post, wasCategorySelected: boolean, token?: string) {
  return (
    dispatch: AppDispatch,
    getState: () => RootState,
    { request }: { request: CommonRequest },
  ) => {
    const isRichContentSchemaEnabled = isExperimentEnabled(
      getState(),
      EXPERIMENT_ENABLE_RICH_CONTENT_SCHEMA_CLIENT,
    );
    dispatch(createPostRequest());
    const postSanitized = isRichContentSchemaEnabled ? post : sanitizeContent(post);
    const body = {
      ...postSanitized,
      hashtags: extractHashtagsFromContent(postSanitized.content),
      mentions: extractMentionsFromContent(postSanitized.content),
    };

    const isCaptchaUsed = !!token;

    const tokenQuery = isCaptchaUsed ? `token=${token}` : '';
    const promise = request.post<CreatePostSuccess>(
      `/posts/?${tokenName}=${createHash(getUniquePostPayloadString(postSanitized))}&${tokenQuery}${
        isRichContentSchemaEnabled ? '&useRichContent=true' : ''
      }`,
      body,
      {
        noRetriesOnErrors: [403, 503, 504],
      },
    );

    return promise
      .then(
        (post) => dispatch(createPostSuccess(post, { isCaptchaUsed, wasCategorySelected })),
        (response) => {
          return dispatch(createPostFailure(response));
        },
      )
      .then(() => promise);
  };
}

export const createPostAndNavigateToItPromisified = (onBeforePostCreateHook: any) =>
  createPromisifiedAction(
    (postRaw: any, wasCategorySelected: boolean) => {
      return async (
        dispatch: Dispatch,
        getState: () => RootState,
        { wixCodeApi }: { wixCodeApi: any },
      ) => {
        let promise = Promise.resolve();
        if (onBeforePostCreateHook.hasHook()) {
          promise = promise.then(() =>
            onBeforePostCreateHook.exec(postRaw).catch(createExternalHookRejectHandler(dispatch)),
          );
        }

        let postToUse: Post;
        return promise
          .then((r: any) => {
            postToUse = isExternalHookResolve(r) ? r.payload || postRaw : postRaw;
            return dispatch(createPost(postToUse, wasCategorySelected));
          })
          .catch(async (response) => {
            if (response.data.errorCode !== CAPTCHA_ERROR_CODE) {
              return Promise.reject(response);
            }

            const token = await getCaptchaToken(dispatch, wixCodeApi);
            return token
              ? dispatch(createPost(postToUse, wasCategorySelected, token))
              : // eslint-disable-next-line prefer-promise-reject-errors
                Promise.reject(response);
          })
          .then((post) => {
            const category = getCategory(getState(), post.categoryId);
            dispatch(closeModal({ type: MODAL_TYPE_CREATE_POST, resolve: false }));
            if (post.draft) {
              dispatch(
                openModal(MODAL_TYPE_POST_PENDING_APPROVAL, {
                  postId: post._id,
                  categoryId: post.categoryId,
                }),
              );
              dispatch(navigateWithinForum(`/${category.slug}`));
            } else {
              dispatch(navigateWithinForum(`/${category.slug}/${post.slug}`));
            }
          })
          .catch((response) => {
            return {
              isSuccessful: false,
              status: response.status,
            };
          });
      };
    },
    ({ isSuccessful = true, status } = {} as any) => ({ isSuccessful, status }),
  );
