import createAction from '../services/create-action';
import { getTranslations } from '../store/translations/translations-selectors';
import { setTranslations } from '../store/translations/translations-actions';
import abTranslate from 'ab-translate/dist/src/abTranslate-runtime';
import { ricosNextFeatureApi } from '../../api/user/ricos-next.api';
import { EXPERIMENT_ENABLE_RCE_NEXT } from '@wix/communities-forum-client-commons';

export const SET_EXPERIMENTS_SUCCESS = 'experiments/SET_EXPERIMENTS_SUCCESS';

export const setExperimentsSuccess = createAction(SET_EXPERIMENTS_SUCCESS);

export function setExperiments(experimentsBag = {}) {
  return async (dispatch, getState, { request, wixCodeApi }) => {
    const currentTranslations = getTranslations(getState());
    const translationsWithExperiments = abTranslate(experimentsBag, currentTranslations);
    dispatch(setTranslations(translationsWithExperiments));

    let rceNextEnabledEnabled = 'false';
    try {
      const result = await request.httpClient.request(
        ricosNextFeatureApi(
          wixCodeApi.location.query?.petri_ovr?.includes(`${EXPERIMENT_ENABLE_RCE_NEXT}:true`),
        ),
      );
      rceNextEnabledEnabled = result.data.enabled;
    } catch {}

    return dispatch(
      setExperimentsSuccess({
        ...experimentsBag,
        [EXPERIMENT_ENABLE_RCE_NEXT]: rceNextEnabledEnabled,
      }),
    );
  };
}
