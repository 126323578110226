import createAction from '../../common/services/create-action';
import { isExperimentEnabled } from '../selectors/experiments-selectors';
import { EXPERIMENT_ENABLE_RICH_CONTENT_SCHEMA_CLIENT } from '@wix/communities-forum-client-commons';

export const FETCH_POST_REQUEST = 'post/FETCH_REQUEST';
export const FETCH_POST_SUCCESS = 'post/FETCH_SUCCESS';
export const FETCH_POST_FAILURE = 'post/FETCH_FAILURE';

export const fetchPostRequest = createAction(FETCH_POST_REQUEST);
export const fetchPostSuccess = createAction(FETCH_POST_SUCCESS);
export const fetchPostFailure = createAction(FETCH_POST_FAILURE);

export function fetchPost(postSlug) {
  return (dispatch, getState, { request }) => {
    const isRichContentSchemaEnabled = isExperimentEnabled(
      getState(),
      EXPERIMENT_ENABLE_RICH_CONTENT_SCHEMA_CLIENT,
    );
    dispatch(fetchPostRequest({ postSlug }));

    const promise = request(
      `/posts/${postSlug}${isRichContentSchemaEnabled ? '?useRichContent=true' : ''}`,
    );

    return promise
      .then((post) =>
        dispatch(
          fetchPostSuccess({
            post,
            postSlug,
          }),
        ),
      )
      .catch(() => dispatch(fetchPostFailure({ postSlug })))
      .then(() => promise);
  };
}
