import { getRichContentFeatures } from '@wix/rich-content-features';
import { getCaptchaSettings } from '../../selectors/forum-data-selectors';
import { hasSuspiciousFields } from '../../../common/services/captcha-helpers';
import { RootState } from '../../types/store-types';
import { DraftContent } from 'ricos-content';
import { RichContent } from 'ricos-schema';

export const shouldShowCaptcha = (state: RootState, commentContent: DraftContent | RichContent) => {
  const {
    enableOnCommentCreation: isCaptchaOnCommentEnabled,
    enableOnSuspiciousActivity: isCaptchaOnSuspiciousEnabled,
  } = getCaptchaSettings(state);

  let isCaptchaForContent = false;

  if (isCaptchaOnSuspiciousEnabled) {
    const contentFeatures = getRichContentFeatures(commentContent);

    isCaptchaForContent = hasSuspiciousFields(contentFeatures);
  }

  return isCaptchaForContent || isCaptchaOnCommentEnabled;
};
